import React from "react";
import { Link } from "react-router-dom";
import NewsletterForm from "../components/NewsletterForm";
import ProductForm from "../components/ProductForm";
import PipedriveForm from "../components/PipedriveForm";
import LeadForm from "../components/LeadForm";

const Footer: React.FC = () => {
  return (
    <div className="bg-black">
      {/* <NewsletterForm/>
            <PipedriveForm formUrl="https://webforms.pipedrive.com/f/72sjlnxg8yT7or0rAmWhSbltuXooqvkv8FnNiHPKxwM8y2QSxCNHuk3U2scGpqE5Yn"/>
            <PipedriveForm formUrl="https://webforms.pipedrive.com/f/cs5ar6a5ZlhoJ5o3htH9CTN6HuVDKofOQ0zBr3yEb9bNHHO9YsEALWl07mUCm49xGb"/>*/}
      {/*  <ProductForm title={"product sara"} />*/}

      <footer className="grid grid-cols-1 sm:grid-cols-4  py-4 pt-32 flex items-center sm:items-start text-white">
        <div className="xl:w-[16rem] h-auto  space-y-4  mb-10 sm:mb-0">
          <Link to="/home">
            <img src="/REVOLV - logo with claim.png" alt="Footer 1" />
          </Link>
          <div className="sm:ml-4 flex justify-center sm:justify-start">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://www.linkedin.com/company/75425254"
            >
              <img src="/footer_icons/Group.svg" alt="Footer 2" />
            </Link>

            {/*<img src="\footer_icons\path1009.svg" alt="Footer 3" />*/}
          </div>
        </div>

        <div className="grid grid-cols-1 col-span-3 sm:flex sm:flex-row text-center sm:text-left space-y-4 sm:space-y-0  justify-between items-start sm:mr-10 xl:mr-[15rem]">
          <div>
            <a href="/home" className="block my-2 sm:my-0">
              HOME
            </a>
            <a href="/about" className="block  my-2 sm:my-0">
              ABOUT
            </a>
            <a href="/careers" className="block  my-2 sm:my-0">
              CAREERS
            </a>
            <a href="/contact" className="block my-2 sm:my-0 ">
              GET IN TOUCH
            </a>
            <a href="/news" className="block  my-2 sm:my-0 ">
              NEWS
            </a>
            <a href="https://www.privacylab.it/informativa.php?23524485155&lang=en" className="block my-2 sm:my-0">
              PRIVACY POLICY
            </a>
          </div>
          <div>
            <p className="uppercase font-bold">Products</p>
            <a href="/products/Sara" className="block my-2 sm:my-0">
              SARA
            </a>
            <a href="/products/Holly" className="block  my-2 sm:my-0">
              HOLLY
            </a>
          </div>

          <div>
            <h2 className="text-1xl font-bold mb-2 uppercase ">
              revolv space srl
            </h2>
            <p className="uppercase">
              Corso Re Umberto 7 <br /> 10121 Torino <br />
              Italy
            </p>
          </div>
          <div>
            <h2 className="text-1xl font-bold mb-2 uppercase">
              revolv space bv
            </h2>
            <p className="uppercase">
            Coenderstraat 5 <br /> 2613 SM Delft <br /> The Netherlands
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
