import React, { useEffect } from "react";
import "../global.css";
import News from "../common/News";
import SimpleSlider from "../common/Slider";
import SliderItem from "../common/SliderItemHome";
import ImageTextHome from "../common/ImageText_Home";
import { Link, useLocation } from "react-router-dom";
import ProductHome from "../common/ProductHome";
import SliderItemHome from "../common/SliderItemHome";
import GradientButtons from "../common/GradientButtons";
import RoundedNews from "../common/news/RoundedNews";

export default function Home() {
  const location = useLocation();
  useEffect(() => {
    if (window.location.hash) {
      let newPath = window.location.pathname + window.location.search;
      if (newPath.endsWith("/")) {
        console.log("termina con /", newPath);
        newPath = newPath.slice(0, -1);
        console.log("newPath", newPath);
      }
      window.history.pushState("", document.title, newPath);
    }
    window.onload = () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <div>
      
      <div className="relative flex flex-col h-full  mt-20">
        {/* BECOME A REVOLVER Section */}
        <div className="h-full flex flex-col   z-40  ">
          <h1 className=" opacity-0 animate-fadeIn delay-0 md:w-4/6 pb-5 mx-12 mt-24  2xl:text-7xl xl:text-7xl lg:text-7xl text-4xl leading-120 tracking-tighter text-left uppercase font-bold text-white dark:text-white  ">
            superpowers <br />
            for satellites
          </h1>
          <div className=" opacity-0 animate-fadeIn delay-1 ml-12 mr-10 uppercase 2xl:text-2xl xl:text-2xl lg:text-2xl text-md  text-white leading-34 tracking-tight text-left max-w-[700px]">
            We redefine small satellite capabilities through <br />
            reliable and affordable space mechanisms and power systems
          </div>

          <GradientButtons
            to1="/home/#products"
            to2="/home/#meetrevolv"
            description1="OUR PRODUCTS"
            description2="MEET REVOLV"
          />
        </div>

        <div className="mx-12 mb-24 h-full flex flex-col bg-opacity-50 z-40 pt-10">
          <div>
            <div className="grid  md:grid-cols-2 gap-4 sm:grid-cols-1 gap-y-20 ">
              <div
                className="value-item"
                data-aos="zoom-out-up"
                data-aos-offset="100"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <ImageTextHome
                  text="SPACE MECHANISMS"
                  imageSrc="SARA_01_ISOMETRICA.png"
                  description="We develop custom mechanisms compatible with the size and budget of small satellite missions, without compromising on reliability"
                  textAlign="left"
                />
              </div>
              <div
                className="value-item"
                data-aos="zoom-out-up"
                data-aos-offset="100"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <ImageTextHome
                  text="SOLAR ARRAYS"
                  imageSrc="PANELS_01_ISOMETRICA.png"
                  description="We provide turnkey power generation systems, customized for your spacecraft and co-engineered with transparency together with your platform design team"
                  textAlign="left"
                />
              </div>
            </div>
          </div>
        </div>

        {/*SLIDER*/}
        <div
          className="relative mb-20 flex items-center justify-center z-30 w-full h-full"
          data-aos="zoom-in-out"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <SimpleSlider>
            {
              <SliderItemHome
                title="For satellite integrators"
                description={
                  "Gone are the days when employing mechanisms on small satellites was a daunting challenge. We're rewriting the rules.\n\nOur actuation systems boost the performance of your small satellites and the capabilities that you can offer to your customers."
                }
              />
            }
            {
              <SliderItemHome
                title="For satellite Operators"
                description={
                  "Forget about low uptimes and satellite unavailability due to the platform limitations.\n\nOur high-performance devices elevate your satellite's potential, boosting the duty cycles of your platforms, your mission return and your revenues. "
                }
              />
            }
          </SimpleSlider>
        </div>

        {/* PRODUCTS Section */}
        <div
          id="products"
          className="py-24 h-full flex flex-col bg-black bg-opacity-50 z-0 justify-center "
        >
          <div className="relative flex items-center w-full">
            <div className="grid  md:grid-cols-1  ">
              <div className="col-span-3 flex justify-center">
                <h1
                  className="text-4xl font-bold text-white"
                  data-aos="zoom-in-out"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  PRODUCTS
                </h1>
              </div>

              <div className="flex flex-col md:flex-row justify-center items-start gap-8 mx-10">
                <div
                  className="value-item w-full"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="300"
                  data-aos-delay="100"
                >
                  <ProductHome
                    subheading="THE PANEL"
                    heading="PAVEL"
                    subtitle="COMING SOON"
                    interactable={false}
                    imageSrc="\HOME_1.png"
                  />
                </div>

                <div
                  className="value-item w-full"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                  data-aos-delay="300"
                >
                  <ProductHome
                    subheading="Solar Array Drive Assembly"
                    heading="SARA"
                    subtitle="TRL 7"
                    interactable={true}
                    imageSrc="\HOME_2.png"
                  />
                </div>
                <div
                  className="value-item w-full"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                  data-aos-delay="400"
                >
                  <ProductHome
                    subheading="THE HINGE"
                    heading="HOLLY"
                    subtitle="TRL 7"
                    interactable={true}
                    imageSrc="/00_vista-isometrica-(1).png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* TRUSTED BY */}
        <div className="flex flex-col  relative ">
          <div className="flex items-center w-full h-screen">
            <div className="grid grid-cols-2 gap-4 text-white w-full mx-5">
              <div className="col-span-2 flex justify-center">
                <h1
                  className="text-4xl font-bold my-14"
                  data-aos="fade-zoom-in"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="800"
                  data-aos-once="true"
                >
                  TRUSTED BY
                </h1>
              </div>

              <div
                className="col-span-2 md:col-span-1 my-6  flex grid-cols-1 md:grid-cols-2 items-center "
                data-aos="fade-zoom-in"
                data-aos-offset="400"
                data-aos-delay="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
                data-aos-once="true"
              >
                <div className="flex items-center justify-center w-full h-full transform transition-transform duration-200 hover:scale-110">
                  <img
                    className="object-cover sm:h-7 md:h-10 lg:h-32 my-4 w-auto"
                    src="/deimos.svg"
                    alt="deimos"
                  />
                </div>
              </div>
              {/*<div
                                className="col-span-3 md:col-span-1 my-6 xl:col-span-1 flex flex-col items-center"
                                data-aos="fade-zoom-in"
                                data-aos-offset="400"
                                data-aos-delay="600"
                                data-aos-easing="ease-in-sine"
                                data-aos-duration="600"
                                data-aos-once="true"
                            >
                                <div
                                    className="flex items-center justify-center w-full h-full transform transition-transform duration-200 hover:scale-110">
                                    <img
                                        className="object-cover "
                                        src="/c3s-logo-color.svg"
                                        alt="c3s"
                                    />
                                </div>
                            </div>*/}
              <div
                className="col-span-2 md:col-span-1 my-6  flex grid-cols-1 md:grid-cols-2 items-center"
                data-aos="fade-zoom-in"
                data-aos-offset="400"
                data-aos-delay="900"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
                data-aos-once="true"
              >
                <div className="flex items-center justify-center w-full h-full transform transition-transform duration-200 hover:scale-110">
                  <img
                    className="object-cover sm:h-7 md:h-10 lg:h-24 w-auto"
                    src="/logo-u-space.svg"
                    alt="u-space"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* NEWS Section */}
          <div className="px-4 md:px-20 mt-10 pt-20 flex flex-col">
            <h1
              className="text-4xl font-bold text-white text-center py-10 mb-8 mt-10"
              data-aos="fade-zoom-in"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="800"
              data-aos-once="true"
            >
              REVOLV IN THE NEWS
            </h1>

            <div
              data-aos="fade-zoom-in"
              data-aos-offset="100"
              data-aos-delay="300"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true"
            >
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://spacenews.com/revolv-space-seeks-to-expand-satellite-duty-cycles/"
              >
                
                <div className="h-full my-1">
      <div className="flex space-x-3 h-full ">
        <div className="w-1/2 h-full flex justify-end mr-8">
          <img
            src={"/IMAGES/homepageLogos/spacenews.png"}
            alt={""}
            className="h-[3rem] w-[12rem] md:h-32 md:w-[30rem] rounded-full object-cover "
          />
        </div>

        <div className="w-1/3 h-full space-y-1 ">
          <h1 className="text-left mt-4 text-white uppercase text-md ">
            {"Revolv Space seeks to expand satellite duty cycles"}
          </h1>
          <p className="relative bottom-0 text-left text-white text-xs overflow-ellipsis line-clamp-4">
            {""}
          </p>
        </div>
      </div>
    </div>
                  {/*<News
                    imageSrc="/IMAGES/homepageLogos/ESA_Logo.svg"
                    description="REVOLV SPACE DEVELOPS CRITICAL COMPONENTS TO ENABLE CONTINUOUS PAYLOAD OPERATIONS"
                  />*/}
                
              </Link>
            </div>

            <div
              data-aos="fade-zoom-in"
              data-aos-offset="100"
              data-aos-delay="400"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true"
            >
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://news.satnews.com/2024/05/28/revolv-space-secures-millione-to-boost-smallsat-performance/"
              >
                <div className="h-full  my-1">
      <div className="flex space-x-3 h-full ">
        <div className="w-1/2 h-full flex justify-end mr-8">
          <img
            src={"/IMAGES/4/small.png"}
            alt={""}
            className="h-[3rem] w-[12rem] md:h-32 md:w-[30rem] rounded-full object-cover "
          />
        </div>

        <div className="w-1/3 h-full space-y-1">
          <h1 className="text-left text-white text-md uppercase mt-4">
            {"Revolv Space secures million€ to boost smallsat performance"}{" "}
          </h1>
          <p className="relative bottom-0 text-left text-white text-xs overflow-ellipsis line-clamp-4">
            {""}
          </p>
        </div>
      </div>
    </div>
                  
              </Link>
            </div>

            <div
              data-aos="fade-zoom-in"
              data-aos-offset="100"
              data-aos-delay="500"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true"
            >
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.spacevoyaging.com/news/2024/11/21/revolv-space-prepares-for-first-in-orbit-demonstration-of-sara/"
              >
                <div className="h-full  my-1">
      <div className="flex space-x-3 h-full ">
        <div className="w-1/2 h-full flex justify-end mr-8">
          <img
            src={"/IMAGES/5/small.png"}
            alt={""}
            className="h-[3rem] w-[12rem] md:h-32 md:w-[30rem] rounded-full object-cover "
          />
        </div>

        <div className="w-1/3 h-full space-y-1">
          <h1 className="text-left text-white text-md uppercase mt-4">
            {"Revolv Space Prepares for First In-Orbit Demonstration of SARA"}{" "}
          </h1>
          <p className="relative bottom-0 text-left text-white text-xs overflow-ellipsis line-clamp-4">
            {""}
          </p>
        </div>
      </div>
    </div>
                  
              </Link>
            </div>

            
          </div>
                  <div  id="meetrevolv"/>

          <h1
              className="text-4xl font-bold text-white text-center py-10 mb-8 mt-[16rem]"
              data-aos="fade-zoom-in"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="800"
              data-aos-once="true"
            >
              MEET REVOLV
            </h1>





          <div className="relative flex flex-col grid grid-cols-1 h-full ">
                   {/* Next Meets Section */}
                    <div className=" sm:px-10  pt-4 flex flex-col md:col-span-2 ">
                      <div
                        data-aos="fade-zoom-in"
                        data-aos-offset="100"
                        data-aos-delay="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        data-aos-once="true"
                        className="flex justify-center my-8  "
                      >
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to="https://www.satshow.com/"
                        >
                          <button className="transform transition-transform duration-200 hover:scale-110">
                            <div className="self-start grid md:grid-cols-2 sm:grid-row sm:grid-row gap-4 text-white w-full py-8">
                              <div className="col-span-1 flex flex-col items-center md:items-end">
                                  <img className="my-2  w-[20rem] xl:w-[22rem] " style={{ filter: "brightness(0) invert(1)" }} src={"/IMAGES/MEETS_LOGOS/Satellite_2025.png"} alt="1" />
                              </div>
                                  <div className="uppercase flex flex-col self-center md:text-left text-center text-lg xl:text-xl ">
                                  {/* <div className="font-bold">Satellite 2025</div>*/}
                                  <div className="text-8 ">Washington DC, 10-13 March<br/>BOOTH 1353</div>
                                      
                                  </div>
                              </div>
                          </button>
                        </Link>
                      </div>
                      <div
                        data-aos="fade-zoom-in"
                        data-aos-offset="100"
                        data-aos-delay="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        data-aos-once="true"
                        className="flex justify-center my-8"
                      >
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to="https://www.euspa.europa.eu/newsroom-events/news/save-date-euspa-industry-days"
                        >
                          <button className="transform transition-transform duration-200 hover:scale-110 ">
                            <div className=" grid md:grid-cols-2 sm:grid-row sm:grid-row gap-4 text-white w-full py-8 items-center">
                              <div className=" col-span-1 flex flex-col items-center md:items-end">
                                  <img className="my-2 w-[10rem] xl:w-[20rem] " style={{ filter: "brightness(0) invert(1)" }} src={"/IMAGES/MEETS_LOGOS/EUSPA_Logo.png"} alt="1" />
                              </div>
                                  <div className="uppercase flex flex-col self-center md:text-left text-center text-lg xl:text-xl  ">
                                      {/* <div className="font-bold">Expandeo</div>*/}
                                      <div className="text-8 ">PRAGUE, 25-26 MARCH</div>
                                      
                                  </div>
                                  
                              </div>
                          </button>
                        </Link>
                      </div>
                      <div
                        data-aos="fade-zoom-in"
                        data-aos-offset="100"
                        data-aos-delay="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        data-aos-once="true"
                        className="flex justify-center my-8"
                      >
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to="https://2025.smallsateurope.com/"
                        >
                          <button className="transform transition-transform duration-200 hover:scale-110">
                            <div className="self-start grid md:grid-cols-2 sm:grid-row sm:grid-row gap-4 text-white w-full py-8">
                              <div className="col-span-1 flex flex-col items-center md:items-end">
                                  <img className="my-2 w-[20rem] xl:w-[22rem] " style={{ filter: "brightness(0) invert(1)" }} src={"/IMAGES/MEETS_LOGOS/SmallSatEU.png"} alt="1" />
                              </div>
                                  <div className="uppercase flex flex-col self-center md:text-left text-center text-lg xl:text-xl  ">
                                  {/* <div className="font-bold">SmallSat EU</div>*/}
                                  <div className="text-8 ">Amsterdam, 27-28 May</div>
                                      
                                  </div>
                              </div>
                          </button>
                        </Link>
                      </div>
          
                      
          
                      
          
                      
                    </div>
                    
                  </div>




          <div className="flex items-center justify-center">
            <Link to="/contact">
              <button
                className="rounded-full w-auto h-10 text-center text-white  font-bold py-2 px-4 transform transition-transform duration-200 hover:scale-110"
                style={{
                  background:
                    "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
                }}
              >
                CONTACT US
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
