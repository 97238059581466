import React, {useState, useEffect} from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

interface CounterProps {
    type: "power" | "letter" | "watt";
    start: number | string;
    end: number | string;
    unit?: string;
    upperText?: string;
}

const Counter: React.FC<CounterProps> = ({type, start, end, unit, upperText}) => {
    const [currentValue, setCurrentValue] = useState<string | number>(start);

    useEffect(() => {
        if (type === 'letter' && typeof end === 'string') { // Ensure end is treated as a string
            // Start with a random three-letter string
            let randomLetters = 'RAED';
            setCurrentValue(randomLetters);
            let letters = randomLetters.split('');

            const intervalId = setInterval(() => {
                letters = letters.map((currentLetter, index) => {
                    const targetLetter = end[index]; // Access end as a string
                    // Gradually change each letter to the target 'LEO'
                    if (currentLetter !== targetLetter) {
                        return nextLetter(currentLetter, targetLetter);
                    }
                    return currentLetter;
                });

                setCurrentValue(letters.join(''));

                // Stop the interval when all letters match 'LEO'
                if (letters.join('') === end) {
                    clearInterval(intervalId);
                }
            }, 500);
        }
    }, [type, end]);

    const generateRandomLetters = () => {
        const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        return Array.from({length: 3}, () => possible.charAt(Math.floor(Math.random() * possible.length))).join('');
    };

    const nextLetter = (currentLetter: string, targetLetter: string) => {
        const currentCharCode = currentLetter.charCodeAt(0);
        const targetCharCode = targetLetter.charCodeAt(0);
        const nextCharCode = currentCharCode < targetCharCode ? currentCharCode + 1 : currentCharCode - 1;
        return String.fromCharCode(nextCharCode);
    };

    return (
        <div className="text-center p-4">

            <div className="text-xl pb-4">{type === 'power' ? 'UP TO' : type === 'watt' ? 'UP TO' : 'FAIL'}</div>

            {type === 'power' || type === 'watt' ? (
                <CountUp start={parseFloat(start.toString())} end={parseFloat(end.toString())} duration={2.5}
                         redraw={true}>
                    {({countUpRef, start}) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <div>
                                <span ref={countUpRef} className="text-6xl font-medium text-center">
                                {currentValue}


                            </span>
                                <span className="text-6xl font-medium text-center uppercase">{unit}</span>
                            </div>
                        </VisibilitySensor>

                    )}

                </CountUp>
            ) : (
                <div className="text-6xl font-medium">{currentValue}</div>
            )}
            <div className="text-xl uppercase  pt-4 ">
                {type === 'power'
                    ? <>POWER GENERATION<br />
                    THAN FIXED ARRAYS
                    <br />(orbit-dependent)</>
                    : type === 'watt'
                        ? <>of power transfer<br />(2 wings, 500w per unit)</>
                        : <>solution thanks to <br />our snapback system</>}
            </div>
        </div>
    );
};

export default Counter;
