import { useEffect, useState } from "react";
import RoundedNews from "../common/news/RoundedNews";
import SmallNews from "../common/news/SmallNews";
import "../global.css";
import Papa from "papaparse";
import { Link, useNavigate } from "react-router-dom";
import { NewsItem } from "../common/news/types";
import { set } from "react-hook-form";

export default function NewsPage() {
  const [news, setNews] = useState<NewsItem[]>([]);
  const [newsFilteredByCategory, setNewsFilteredByCategory] =
    useState<NewsItem[]>(news);
  const [uniqueCategories, setUniqueCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [seeMore, setSeeMore] = useState(false); // Aggiunto per gestire il pulsante "Vedi di più" [vedi sotto
  const [isLoading, setIsLoading] = useState(true); // Aggiunto per tracciare lo stato di caricamento

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAndSortNews = async () => {
      const response = await fetch("/csv/news_content.csv");
      const csvText = await response.text();
      Papa.parse(csvText, {
        header: true,
        complete: (result) => {
          const sortedNews = (result.data as NewsItem[]).sort(
            (a, b) =>
              new Date(b.publication_date).getTime() -
              new Date(a.publication_date).getTime()
          );

          setNews(sortedNews);
          const uniqueCategories = Array.from(
            new Set(sortedNews.map((item) => item.category))
          );

          setUniqueCategories(uniqueCategories);

          console.log("le categorie sono", uniqueCategories);

          setIsLoading(false); // Aggiornamento dello stato di caricamento
        },
      });
    };

    fetchAndSortNews();
  }, []);

  if (isLoading) {
    return <div>Caricamento in corso...</div>; // O qualsiasi altro segnaposto di caricamento
  }

  return (
    <div>
      <div className="h-full">
        <h1 className="text-4xl md:text-6xl font-bold text-white text-center pt-32 "
            data-aos="fade-zoom-in"
            data-aos-offset="100px"

            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
            data-aos-once="true">
          MOST RECENT
        </h1>
        <div className="grid md:grid-cols-2 sm:grid-row gap-4 text-white px-8 py-7"
             data-aos="fade-zoom-in"
             data-aos-offset="100px"

             data-aos-easing="ease-in-sine"
             data-aos-duration="600"
             data-aos-once="true">
          <div
            className="col-span-1 cursor-pointer"
            onClick={() => {
              if (news[0].link) {
                console.log("LA NEEWS HA IL LINK");
                window.open(news[0].link, "_blank", "noopener,noreferrer");
              } else {
                navigate(`/news/${news[0].category}/${news[0].news_id}`, {
                  state: {
                    NewItem: news[0],
                    filteredNews: news.filter(
                      (item) => item.category === news[0].category
                    ),
                  },
                });
              }
            }}
          >
            <img
              className="my-2 w-full h-max-80 object-cover "
              data-aos="fade-zoom-in"
              data-aos-offset="100px"

              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true"
              src={"/IMAGES/" + news[0].news_id + "/small.png"}
              alt="1"
            />
            <div className="grid grid-cols-2 gap-4 pt-4"
                 data-aos="fade-zoom-in"
                 data-aos-offset="100px"

                 data-aos-easing="ease-in-sine"
                 data-aos-duration="600"
                 data-aos-once="true">
              <div className="col-span-1">
                <h2 className="text-xl  text-left line-clamp-2 ">
                  {news.length > 0 ? news[0].titleNews : "NO NEWS"}
                </h2>
              </div>
              <div className="col-span-1">
                <p className="text-sm  text-left line-clamp-4 ">
                  {news.length > 0 ? news[0].subtitle : "NO NEWS"}
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-1"
               data-aos="fade-zoom-in"
               data-aos-offset="100px"

               data-aos-easing="ease-in-sine"
               data-aos-duration="600"
               data-aos-once="true">
            <div className="flex flex-col h-full space-y-4">
              {news.slice(1, 4).map((newsItem, index) => (
                <div
                  onClick={() => {
                    if (newsItem.link) {
                      // Se newsItem ha un link, reindirizza l'utente a quel link
                      window.open(newsItem.link, "_blank");
                    } else {
                      // Altrimenti, procedi con la navigazione a newsDetail
                      navigate(
                        `/news/${newsItem.category}/${newsItem.news_id}`,
                        {
                          state: {
                            NewItem: newsItem,
                            filteredNews: news.filter(
                              (item) => item.category === newsItem.category
                            ),
                          },
                        }
                      );
                    }
                  }}
                  key={index} // Remember to add a key when mapping
                  className="cursor-pointer"
                >
                  <RoundedNews
                    key={index}
                    title={newsItem.titleNews}
                    description={newsItem.subtitle}
                    imageSrc={"/IMAGES/" + newsItem.news_id + "/small.png"}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="h-full">
        <div className="bg-gradient-to-r from-gradient-start to-gradient-end h-0.5"></div>
        <h1 className="text-4xl md:text-6xl font-bold text-white text-center pt-32 "
            data-aos="fade-zoom-in"
            data-aos-offset="100px"

            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
            data-aos-once="true">
          ALL NEWS
        </h1>
      </div>
      {/* Bottoni delle categorie */}
      <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 p-4"
           data-aos="fade-zoom-in"
           data-aos-offset="100px"

           data-aos-easing="ease-in-sine"
           data-aos-duration="600"
           data-aos-once="true">
        <button
          className={`flex items-center justify-center p-[2px] h-14 w-20 bg-gradient-to-r  from-gradient-start to-gradient-end text-white  rounded-full ${
            selectedCategory === null
              ? "bg-gradient-to-r from-gradient-start to-gradient-end  "
              : ""
          }`}
          onClick={() => {
            // Seleziona/deseleziona la categoria
            setSelectedCategory(null);
            setNewsFilteredByCategory(
              news.filter((item) => item.category === null)
            );
          }}
        ><div className={`flex hover:bg-transparent h-full w-full items-center justify-center rounded-full bg-black ${
          selectedCategory === null
            ? "bg-transparent "
            : ""
        }`}>
          ALL
          </div>
        </button>
        {uniqueCategories.map((category, index) => (
          <button
          key={category}
          style={{
            background: "",
            border: "linear-gradient(90deg, #00858F 0%, #E6007E 100%)"
        }}
          className={`flex items-center justify-center p-[2px] h-14 w-48 bg-gradient-to-r  from-gradient-start to-gradient-end text-white  rounded-full ${
            selectedCategory === category
              ? "bg-gradient-to-r from-gradient-start to-gradient-end "
              : ""
          }`}
          onClick={() => {
            // Seleziona/deseleziona la categoria
            setSelectedCategory(
              selectedCategory === category ? null : category
            );
            setNewsFilteredByCategory(
              news.filter((item) => item.category === category)
            );
          }}
        ><div className={`flex hover:bg-transparent h-full w-full items-center justify-center rounded-full bg-black ${
          selectedCategory === category
            ? "bg-transparent "
            : ""
        }`}>
          {category}
          </div>
        </button>
        ))}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3  gap-4 p-8"
           data-aos="fade-zoom-in"
           data-aos-offset="100px"

           data-aos-easing="ease-in-sine"
           data-aos-duration="600"
           data-aos-once="true">
        {(selectedCategory ? newsFilteredByCategory : news)
          .slice(0, seeMore ? undefined : 12) // Usa slice per limitare le notizie visualizzate
          .map((newsItem, index) => (
            <div
              onClick={() => {
                if (newsItem.link) {
                  window.open(newsItem.link, "_blank", "noopener,noreferrer");
                } else {
                  navigate(`/news/${newsItem.category}/${newsItem.news_id}`, {
                    state: {
                      NewItem: newsItem,
                      filteredNews: news.filter(
                        (item) => item.category === newsItem.category
                      ),
                    },
                  });
                }
              }}
              key={index} // Remember to add a key when mapping
              className="cursor-pointer"
            >
              <SmallNews
                title={newsItem.titleNews}
                description={newsItem.subtitle}
                imageSrc={"/IMAGES/" + newsItem.news_id + "/small.png"}
                category={newsItem.category}
                readingTime={newsItem.reading_time}
              />
            </div>
          ))}
      </div>
      <div className="flex justify-center"
           data-aos="fade-zoom-in"
           data-aos-offset="100px"

           data-aos-easing="ease-in-sine"
           data-aos-duration="600"
           data-aos-once="true">
        {/* Mostra il pulsante "SEE MORE" solo se ci sono più di 16 notizie */}
        {(selectedCategory ? newsFilteredByCategory : news).length > 16 &&
          !seeMore && (
            <button
              onClick={() => {
                setSeeMore(!seeMore); // Aggiornamento dello stato di "Vedi di più"
              }}
              className=" with-border hover:bg-gradient-to-r from-gradient-start to-gradient-end text-white py-2 px-4 rounded "
            >
              SEE MORE
            </button>
          )}
      </div>
    </div>
  );
}
