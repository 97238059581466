import React from "react";

interface TeamProps {
  imageSrc?: string;
  name?: string;
  role?: string;
}

class TeamPerson extends React.Component<TeamProps> {
  render() {
    const { imageSrc, name, role } = this.props;

    return (
      <div className="flex flex-col items-center justify-center text-white w-full py-8">
        <div className="relative w-24 h-24 md:w-48 md:h-48 lg:w-52 lg:h-52">
          <img className="rounded-full w-full h-full object-cover " src={imageSrc} alt="1" />
          <div
            className="absolute inset-0 rounded-full opacity-0"
            style={{
                background: "linear-gradient(90deg, #E6007E 7%, #00858F 100%)",
            }}
          />
        </div>
        <p className="uppercase mt-6 font-bold text-xl  text-center">{name}</p>
        <p className="uppercase mt-2 font-normal text-xl text-center">{role}</p>
      </div>
    );
  }
}

export default TeamPerson;