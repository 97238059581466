import React from "react";
import {Link} from "react-router-dom";

interface NewsPropsHome {
  description?: string;
  title?: string;
}

class SliderItemHome extends React.Component<NewsPropsHome> {
  render() {
    const { description, title } = this.props;

    return (
      <div className=" relative h-screen overflow-hidden flex items-center justify-center">
        <img
          className="object-cover md:opacity-50 md:w-full h-1/2 md:h-4/6 lg:h-full z-10 ml-5 "
          alt="REVOLVE SPACE_WEB BACKGROUND_03_CAREER 1.jpg"
          src="/render_erica/REVOLVE SPACE_WEB BACKGROUND_03_CAREER 1.jpg"
        />
        <div className="absolute z-30 inset-0 flex items-center justify-center text-white uppercase">
          <div className="flex flex-col items-center">
            <h1 className="text-4xl w-full md:w-1/2  leading-120  text-center font-bold">
              {title}
            </h1>
              {description && (<p className="my-10 uppercase z-30 text-xs md:text-xs xl:text-base  leading-120  text-center md:w-1/3 w-4/6 " dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br />') }}>

              </p>)}
            <Link to="/contact">

              {/*<button
              className="relative font-bold flex items-center justify-center w-auto h-10 text-center text-white py-2 px-4"
              style={{
                border: "1px solid white",
              }}
            >
              FIND OUT HOW
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2 w-6 h-6"
              >
                <path
                  d="M9.00003 16.1885L9.00003 0.0191645L11 0.0191646L11 16.1885L18.5949 8.59356L20 10.0192L10 20.0192L-3.91621e-07 10.0192L1.40511 8.59356L9.00003 16.1885Z"
                  fill="url(#paint0_linear_362_14)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_362_14"
                    x1="10.1324"
                    y1="-1.42338"
                    x2="10.1324"
                    y2="19.8899"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#E6007E" />
                    <stop offset="1" stop-color="#00858F" />
                  </linearGradient>
                </defs>
              </svg>
            </button>*/}
            </Link>

            <Link to="/contact">
              <button
                  className="rounded-full w-auto h-10 text-center text-white  font-bold py-2 px-4  transform transition-transform duration-200 hover:scale-110"
                  style={{
                    background: "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
                  }}
              >
                FIND OUT HOW
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default SliderItemHome;
