import React, { useEffect, useState } from 'react';
import axios from 'axios';

type LeadFormProps = {
    minMass: number;
    maxMass: number;
    minPower: number;
    maxPower: number;
    configBase: string;
    PWRMultiplier: number;
    qty: string;
};

const LeadForm: React.FC<LeadFormProps> = ({ minMass, maxMass, minPower, maxPower, configBase, PWRMultiplier, qty }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    const [notes, setNotes] = useState('');
    const [configuration, setConfiguration] = useState(configBase);
    const [totMinMass, setTotMinMass] = useState(minMass);
    const [totMaxMass, setTotMaxMass] = useState(maxMass);
    const [totMinPower, setTotMinPower] = useState(minPower * PWRMultiplier);
    const [totMaxPower, setTotMaxPower] = useState(maxPower * PWRMultiplier);
    const [interestedQuantity, setInterestedQuantity] = useState(qty.toString());
    const [satQuantity, setSatQuantity] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        setTotMinMass(minMass);
        setTotMaxMass(maxMass);
        setTotMinPower(minPower * PWRMultiplier || 0); // default to 0 if NaN
        setTotMaxPower(maxPower * PWRMultiplier || 0); // default to 0 if NaN
        setConfiguration(configBase);
        setInterestedQuantity(qty.toString());
    }, [minMass, maxMass, minPower, maxPower, configBase, PWRMultiplier, qty]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setError('');
        setSuccess('');

        try {
            const personId = await createOrGetPerson(name, email, phone);
            const organizationId = company ? await createOrGetOrganization(company) : null;

            const leadData = {
                title: name,
                person_id: personId,
                organization_id: organizationId,
                'b48a5b6a9b70cdf46d23677a08298086133c518c': configuration,
                'f2924a9834039661e73406e07b5b36de48673f70': totMinMass,
                '49aad253fbf6b59c2117e48778886aaaa7569324': totMaxMass,
               '86c4e4cda01eff2341a1bbdff0c131559a2c9270': totMinPower,
                '485910a501b92f72af66d598769095edc0570e1f': totMaxPower,
                'bc84c709967f8cea4087c55208ed7a7075324328': interestedQuantity,
                'a4e180aae43e97f55cb88fb4590f9fa4b261d2bc': satQuantity,
                'aa53bed4bb17d932a62330a16cec84121048319e': notes,
            };

            console.log('Submitting lead data:', leadData);

            const response = await axios.post(
                `https://api.pipedrive.com/v1/leads?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`,
                leadData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status === 201 || response.status === 200) {
                setSuccess('Request sent successfully!');
                setName('');
                setEmail('');
                setPhone('');
                setCompany('');
                setConfiguration(configBase);
                setTotMinMass(minMass);
                setTotMaxMass(maxMass);
                setTotMinPower(minPower * PWRMultiplier || 0);
                setTotMaxPower(maxPower * PWRMultiplier || 0);
                setInterestedQuantity(qty.toString());
            } else {
                setError(`Failed to send request. Status code: ${response.status}`);
            }
        } catch (err) {
            if (axios.isAxiosError(err)) {
                console.error('Error data:', err.response?.data);
                setError(`Error: ${err.response?.data?.error || err.message}`);
            } else {
                setError('Failed to create lead. Please try again.');
            }
        }
    };

    return (
        <div className="max-w-md mx-auto mt-10 p-3 bg-transparent text-white uppercase">
            <h2 className="text-2xl font-bold mb-6 text-left">get your model</h2>
            <p className="my-4">
                Receive the results of your configuration, such as power and mass directly in your inbox.</p>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block">Name</label>
                    <input
                        type="text"
                        className="w-full px-4 py-2 border bg-neutral-700"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block">Email</label>
                    <input
                        type="email"
                        className="w-full px-4 py-2 border bg-neutral-700"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block">Phone number (optional)</label>
                    <input
                        type="text"
                        className="w-full px-4 py-2 border bg-neutral-700"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}

                    />
                </div>
                <div className="mb-4">
                    <label className="block">Company / affiliation</label>
                    <input
                        type="text"
                        className="w-full px-4 py-2 border bg-neutral-700"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block">NUMBER OF SATELLITES PLANNED FOR THIS PROJECT</label>
                    <select
                        className="w-full px-4 py-2 border bg-neutral-700"
                        id="satellites"
                        name="satellites"
                        value={satQuantity}
                        onChange={(e) => setSatQuantity(e.target.value)}
                        required={true}
                    >
                        <option value="1">1</option>
                        <option value="2-5">2-5</option>
                        <option value="6-10">6-10</option>
                        <option value="11-50">11-50</option>
                        <option value="50-100">50-100</option>
                        <option value=">100">100+</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block">Additional notes</label>
                    <input
                        type="text"
                        className="w-full px-4 py-2 border bg-neutral-700"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                    />
                </div>
                <div className="mb-4 hidden">
                    <label className="block">Your Configuration</label>
                    <input
                        type="hidden"
                        className="w-full px-4 py-2 border bg-neutral-700"
                        value={configuration}
                        readOnly={true}
                    />
                </div>
                <input type="hidden" value={totMinMass} />
                <input type="hidden" value={totMaxMass} />
                <input type="hidden" value={totMinPower} />
                <input type="hidden" value={totMaxPower} />
                <input type="hidden" value={interestedQuantity} />
                {error && <p className="text-red-500">{error}</p>}
                {success && <p className="text-blue-400">{success}</p>}
                <button
                    type="submit"
                    className="w-full rounded-full text-white py-2 uppercase font-bold bg-gradient-to-r from-gradient-start to-gradient-end hover:from-gradient-start hover:to-gradient-end transition-transform duration-200 transform hover:scale-105"
                    style={{
                        background: "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
                    }}
                >
                    Get your model specs
                </button>

            </form>
        </div>
    );
};

export default LeadForm;

const createOrGetPerson = async (name: string, email: string, phone: string) => {
    try {
        const response = await axios.post(
            `https://api.pipedrive.com/v1/persons?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`,
            {
                name,
                email: [{ label: 'work', value: email }],
                phone: [{ label: 'work', value: phone }]
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );

        return response.data.data.id;
    } catch (error) {
        console.error('Error creating or retrieving person:', error);
        throw new Error('Failed to create or retrieve person');
    }
};

const createOrGetOrganization = async (name: string) => {
    try {
        const response = await axios.post(
            `https://api.pipedrive.com/v1/organizations?api_token=${process.env.REACT_APP_PIPEDRIVE_API_TOKEN}`,
            { name },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );

        return response.data.data.id;
    } catch (error) {
        console.error('Error creating or retrieving organization:', error);
        throw new Error('Failed to create or retrieve organization');
    }
};
