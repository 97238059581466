import ImageText from "../common/ImageText";
import Counter_holly from "../common/Counter_holly";
import ProductDescription from "../common/ProductDescription";
import Collapsible from "../common/Collapsible";
import TechSpecs from "../common/TechSpecs";
import ProductSlider from "../common/ProductSlider";
import EmailPopup from '../common/EmailPopup';
import "../global.css";
import { Link } from "react-router-dom";
import React, {useEffect, useState} from "react";
import OverlayForm from "../components/OverlayForm";
import SliderHolly from "../common/SliderHolly";

export default function Holly() {

  useEffect(() => {
    if (window.location.hash) {
      let newPath = window.location.pathname + window.location.search;
      if (newPath.endsWith('/')) {
        console.log("termina con /", newPath)
        newPath = newPath.slice(0, -1);
        console.log("newPath", newPath)
      }
      window.history.pushState("", document.title, newPath);
    }
  }, []);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [fileType, setFileType] = useState<string | null>(null);

  const handleRequestClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
      <div>
    
        <img
          src="/products/Holly/HOLLY.png"
          alt=""
          className="absolute mt-[6rem] md:mt-2  px-[4rem] md:px-[15rem] lg:px-[20rem] xl:px-[30rem] 2xl:px-[40rem] w-full h-auto object-cover opacity-75 bg-transparent "
          data-aos="fade-zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
          data-aos-once="true"
        />
      </div>
      <div className="relative flex flex-col h-full  mt-20">
        {/* TITLE Section */}
        <div className="h-screen flex flex-col   z-40 ">
          {/* Your content here */}
          <h1 className="opacity-0 animate-fadeIn delay-0 md:w-4/6 pb-5 mx-12 mt-24  2xl:text-7xl xl:text-7xl lg:text-7xl text-4xl leading-120 tracking-tighter text-left uppercase font-bold text-white dark:text-white  ">
            HOLLY
          </h1>
          <div className="opacity-0 animate-fadeIn delay-1 mx-12   uppercase 2xl:text-2xl xl:text-2xl lg:text-2xl text-1xl  text-white leading-34 tracking-tight text-left max-w-1/2">{/*max-w-[700px] */}
            ECSS-COMPLIANT DEPLOYMENT HINGE <br/> TAILORED FOR SMALL SATELLITES
           
          </div>

          <div className=" flex flex-col  sm:flex-row justify-center items-center gap-2 md:gap-24 py-48 lg:py-64 xl:py-96 font-bold " data-aos="fade-zoom-in">
            <Link to="/products/holly/#main-features">

            <button
              className="rounded-full opacity-0 animate-fadeIn delay-2 w-auto h-10 text-center text-white  px-4 transform transition-transform duration-200 hover:scale-110 "
              style={{
                background: "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
              }}
            >
              MAIN FEATURES
            </button>
            </Link>

            <Link to="/products/holly/#tech-specs">
            <button
              className= "rounded-full opacity-0 animate-fadeIn delay-2 w-auto h-10 text-center text-white px-4 transform transition-transform duration-200 hover:scale-110"
              style={{
                background: "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
              }}
            >
              TECHNICAL SPECIFICATIONS
            </button>
            </Link>
          </div>
        </div>

        {/*ANIMATED BLOCKS*/}
        <div className="  h-full flex flex-col  z-0 my-20">

          <div className=" grid grid-cols-1 md:grid-cols-3 gap-4 px-2 sm:mt-0 text-white">

            <Counter_holly type="letter" start="" end="RENDUNDANT" />
            <Counter_holly type="power" start={0.1} end={10} unit="X" />
            <Counter_holly type="watt" start="" end="LATCHING"   />

          </div>
        </div>
        <div id='main-features' className="h-20"></div>

        {/* OPEN POSITIONS Section */}
        <div className="px-10">
          <div
            className="flex items-center justify-center"
            data-aos="fade-zoom-in"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >

          <div>
            <div>
              {/* GIF sopra l'immagine di sfondo */}
              <img
          src="/00_vista-isometrica-(1)-01.png"
          alt="Sara Gif"
          className="relative  w-auto  md:h-[20rem] xl:h-[30rem] object-cover opacity-75 bg-transparent "
          data-aos="fade-zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
          data-aos-once="true"
        />
            </div>
          </div>
            
          </div>
          <div>
            {/*cta download*/}
            <div
              className="flex flex-col  sm:flex-row justify-center items-center gap-2 md:gap-24 py-12 font-bold"
              data-aos="fade-zoom-in"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              {/*<Link to="">*/}
              <button
                className="rounded-full w-auto h-10 text-center text-white  px-4 transform transition-transform duration-200 hover:scale-110"
                style={{
                  background:
                    "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
                }}
                onClick={() => handleRequestClick()}
              >
                REQUEST STEP FILE
              </button>
              {/* </Link>*/}
              {/*<Link to="">*/}
              <button
                className="rounded-full w-auto h-10 text-center text-white px-4 transform transition-transform duration-200 hover:scale-110"
                style={{
                  background:
                    "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
                }}
                onClick={() => handleRequestClick()}
              >
                REQUEST DATASHEET
              </button>
              {/*</Link>*/}
            </div>
          </div>

         

          
          

        </div>
        

        























        {/* COLLAPSIBLE Section */}
       
        {/*AVAILABLE VERSIONS Section*/}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 ">
          {/* Your content here */}
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-bold text-white ">
            AVAILABLE VERSIONS{" "}
          </h1>

          <div>
            <div className="grid  md:grid-cols-2  gap-4 px-4 sm:grid-cols-1">
              <div className="p-4">
                {/*bg-green-500*/}
                <div className="value-item"
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="500"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/products/Holly/90DEG.svg"
                    title=""
                    description={"deployed\nat a 90° angle"}
                    textAlign="center"
                  />
                </div>
              </div>

              <div className=" p-4">
                {/*bg-green-500*/}
                <div className="value-item "
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="800"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/products/Holly/180DEG.svg"
                    title=""
                    description={"deployed\nat a 180° angle"}
                    textAlign="center"
                  />
                </div>
              </div>

              
            </div>
          </div>
        </div>
                  <div id="tech-specs" className="h-10"></div>
        {/* TECHNICAL SPECIFICATIONS Section */}
        <div  className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40">
          {/* Your content here */}
          <h1
              data-aos="fade-zoom-in"
              data-aos-offset="300px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-bold text-white ">
            TECHNICAL SPECIFICATIONS{" "}
          </h1>

          <div>
            <div className="grid gap-4 px-4 grid-cols-1 md:mx-[10rem] lg:mx-[20rem] xl:mx-[30rem] 2xl:mx-[40rem]" >

             <div
                 data-aos="fade-zoom-in"
                 data-aos-offset="300px"
                 data-aos-delay="400"
                 data-aos-easing="ease-in-sine"
                 data-aos-duration="600">
               <TechSpecs
                   title=""
                   description={[
                     { title: "Dimensions(stowed): ", description: "16x69x18mm" },
                     { title: "Dimensions(deployed): ", description: "16x69x25mm" },
                     { title: "Mass: ", description: "0.018kg" },
                     { title: "Deployment angle: ", description: "90° / 180°" },
                     {title: "deployment angle accuracy: ", description: "+/- 1°",},
                     { title: "Deployment torque: ", description: " 70 NMM (2x 35 NMM springs)" },
                     { title: "Operating temperatures: ", description: "-110 / +100 °C" },
                   ]}
               />
             </div>
            </div>
          </div>
        </div>

        {/* QUALIFICATION Section */}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40" >
          {/* Your content here */}
          <h1
              data-aos="fade-zoom-in"
              data-aos-offset="300px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-bold text-white ">
            QUALIFICATION{" "}
          </h1>

          <div>
            <div className="grid text-center md:grid-cols-3 gap-4 px-4 sm:grid-cols-1 gap-y-20 items-center align-top " data-aos="zoom-out-in"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600">
              
              <div className="value-item"
                   data-aos="fade-zoom-in"
                   data-aos-offset="300px"
                   data-aos-delay="800"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600">
                <ImageText
                  text="Vibration"
                  imageSrc="/products/product_q_vibration.svg"
                  description={"STATIC LOADS \n SINE SWEEP \n RANDOM VIBRATION"}
                  textAlign="center"
                />
              </div>
              <div className="value-item"
                   data-aos="fade-zoom-in"
                   data-aos-offset="300px"
                   data-aos-delay="1200"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600">
                <ImageText
                  text="thermal vacuum"
                  imageSrc="/products/product_q_thermal.svg"
                  description={"7 cycles \n Deployment in cold case"}
                  textAlign="center"
                />
              </div>
              <div className="value-item"
                   data-aos="fade-zoom-in"
                   data-aos-offset="300px"
                   data-aos-delay="1600"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600">
                <ImageText
                  text="fatigue"
                  imageSrc="/products/product_q_fatigue.svg"
                  description={"50 deployment \n sequences in ambient"}
                  textAlign="center"
                />
              </div>
            </div>
          </div>
        </div>

    
        {/* PRODUCT GALLERY Section */}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 my-40">
          {/*  */}
          <h1
              data-aos="fade-zoom-in"
              data-aos-offset="300px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-40  text-5xl leading-120 tracking-tighter text-center uppercase font-bold text-white ">
            PRODUCT GALLERY{" "}
          </h1>

          <div data-aos="fade-zoom-in"
               data-aos-offset="300px"
               data-aos-delay="300"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600">
            <SliderHolly />
          </div>
        </div>

        <Link to="/contact">
          <div className="flex items-center justify-center">
            <button
              className="rounded-full w-auto h-10 text-center text-white  font-bold py-2 px-4 transform transition-transform duration-200 hover:scale-110"
              style={{
                background: "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
              }}
            >
              CONTACT US
            </button>
          </div>
        </Link>
      </div>
      {/* {isPopupOpen && fileType && (
          <EmailPopup fileType={fileType} onClose={handleClosePopup} />
      )}*/}
      {isPopupOpen && (
          <OverlayForm
              formUrl="https://webforms.pipedrive.com/f/6UR9Re8nR5VKrsxbJHNTBDLcsXv6xjIStMZYDU4iiOvEJdEo7cZwg4M1uAm9NAG7WX"
              onClose={handleClosePopup}
          />
      )}
    </div>
  );
}
