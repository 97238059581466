import ReactGA from "react-ga4";

export const initGA = (trackingId: string | undefined) => {
    if(trackingId === undefined)
    {
        console.error("Please set the ANALYTICS environment variable to your Google Analytics measurement ID.");
}
    else {
        ReactGA.initialize(trackingId);
        console.log("google analytics ok :D");
    }
};

export const logPageView = (page: string, title: string) => {
    ReactGA.send({
        hitType: "pageview",
        page,
        title,
    });
};
