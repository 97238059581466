import "../global.css";
import SimpleSlider from "../common/Slider";
import SliderItemAbout from "../common/SliderItemAbout";
import TeamPerson from "../common/TeamPerson";
import CircleSlider from "../common/CircleSlider";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import GradientButtons from "../common/GradientButtons";

export default function About() {
  useEffect(() => {
    if (window.location.hash) {
      let newPath = window.location.pathname + window.location.search;
      if (newPath.endsWith('/')) {
        console.log("termina con /", newPath)
        newPath = newPath.slice(0, -1);
        console.log("newPath", newPath)
      }
      window.history.pushState("", document.title, newPath);
    }
  }, []);
  return (
    <div>

      <div className="relative flex flex-col h-full  mt-20">
        {/* BECOME A REVOLVER Section */}
        <div className="h-full flex flex-col   z-40 ">
          {/* Your content here */}
          <h1 className=" opacity-0 animate-fadeIn delay-0 md:w-5/6 pb-5 mx-12 mt-24  2xl:text-7xl xl:text-7xl lg:text-7xl text-4xl leading-120 tracking-tighter text-left uppercase font-bold text-white dark:text-white  ">
            your trusted supplier <br/>for ambitious missions{" "}
          </h1>
          <div className=" opacity-0 animate-fadeIn delay-1 ml-12 mr-10 uppercase 2xl:text-2xl xl:text-2xl lg:text-2xl text-md  text-white leading-34 tracking-tight text-left max-w-[1000px]">
            we combine cutting-edge technology with an agile development
            approach and a constant feedback loop with the client
          </div>

          <GradientButtons to1="/about/#our-values"  description1=" OUR VALUES" to2="/about/#timeline" description2="OUR STORY" />

        </div>

        {/* ABOUT US */}

        <div  className="flex justify-center pt-72 pb-10">
          <h1  id="our-values" className="opacity-0 animate-fadeIn delay-2 text-4xl md:text-6xl font-bold text-white text-center uppercase">
            what we stand for
          </h1>
        </div>
        <div
       
          className="relative mb-20  flex items-center justify-center h-full "
          data-aos="zoom-out-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <SimpleSlider>
            {
              <SliderItemAbout
                imgSrc="TRANSPARENCY.svg"
                title="TRANSPARENCY"
                titleBefore="what’s going wrong"
                titleAfter="how we fix it"
                descriptionBefore="Building space hardware is not a cakewalk. Suppliers who don’t acknowledge that become black boxes, leaving customers in the dark, upping risks and stifling innovation."
                descriptionAfter="We have a customer-centric approach and we tap into the latest communication tools to keep you in the know, always. Consider us part of your  team, blending in effortlessly."
              />
            }
            {
              <SliderItemAbout
                imgSrc="RESPONSIVENESS.svg"
                title="CUSTOMER FIRST"
                titleBefore="what’s going wrong"
                titleAfter="how we fix it"
                descriptionBefore="We get it, nobody likes bombarding emails for a simple response. frequent Radio silence Kills progress and trust."
                descriptionAfter="an answer is always better than silence. if we are swamped, we will let you know when we will get back to you. And we'll actually follow through."
              />
            }
            {
              <SliderItemAbout
                imgSrc="AGILITY.svg"
                title="Agility"
                titleBefore="what’s going wrong"
                titleAfter="how we fix it"
                descriptionBefore="Slow development and budget blowouts. Old-school aerospace suppliers just don't vibe with the new space game."
                descriptionAfter="Affordability and reliability aren't enemies. We smash that myth with our technical excellence, creative engineering approach, and team resilience."
              />
            }
            {
              <SliderItemAbout
                imgSrc="REILABILITY.svg"
                title="Reliability"
                titleBefore="what’s going wrong"
                titleAfter="how we fix it"
                descriptionBefore="the new space industry is moving from “it just has to  work” to “it has to work perfectly, always”.

cutting corners in the development does not pay off. "
                descriptionAfter="extensive testing, continuous client involvement, and learning from the best.

we know you care about performance and we work hard to achieve just that."
              />
            }
          </SimpleSlider>
        </div>

        {/*TEAM PART*/}
        <div className="px-10 pt-28">
          <h1 className="text-4xl md:text-6xl font-bold text-white text-center py-4 uppercase">
            meet the revolvers
          </h1>
          <div
            className="grid grid-cols-2 md:grid-cols-4 gap-5 items-start"
            data-aos="zoom-out-up"
            data-aos-offset="400px"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
            data-aos-delay="0"

          >
            <TeamPerson 
            imageSrc="/team/1 MARCO.png" 
            name="marco sala" 
            role="CEO" 
            />
            <TeamPerson
              imageSrc="/team/2 MICHAL.png"
              name="michal grendysz"
              role="cto"
            />
            <TeamPerson
              imageSrc="/team/3 FILIPPO.png"
              name="filippo oggionni"
              role="CCO"
            />
            <TeamPerson
              imageSrc="/team/4 ALEKSANDER.png"
              name="aleksander fiuk"
              role="coo"
            />

            <TeamPerson
              imageSrc="/team/5 FRANCESCO.png"
              name="francesco pescarmona"
              role="head of mechanical systems "
            />
            <TeamPerson
              imageSrc="/team/6 PAVLOS.png"
              name="pavlos kakouris"
              role="mechanical engineer"
            />
            <TeamPerson
              imageSrc="/team/7 JUAN DAVID.png"
              name="JUAN DAVID LASANTA GARCÍA"
              role="MECHANICAL ENGINEER"
            />
            <TeamPerson
              imageSrc="/team/8 DIEGO.png"
              name="DIEGO CREMONESI"
              role="MECHANICAL ENGINEER"
            />
            <TeamPerson
              imageSrc="/team/9 LESZEK.png"
              name="leszek garbaciak"
              role="ait engineer"
            />
            <TeamPerson
              imageSrc="/team/10 SLAWOMIR.png"
              name="slawomir malkowski"
              role="head of embedded systems"
            />

            <TeamPerson
              imageSrc="/team/11 RENE.png"
              name="rene laflamme"
              role="software developer"
            />
            <TeamPerson
              imageSrc="/team/12 JANUSZ.png"
              name="JANUSZ JANASZEK"
              role="ELECTRONIC ENGINEER"
            />
            <TeamPerson
              imageSrc="/team/13 ALBERTO.png"
              name="ALBERTO ZORZETTO"
              role="SYSTEM ENGINEER"
            />
            <TeamPerson
              imageSrc="/team/14 CLAUDIA.png"
              name="claudia landolfo"
              role="executive assistant"
            />
            <TeamPerson
              imageSrc="/team/15 GUILHERME.png"
              name="GUILHERME REIS"
              role="AIT ENGINEERING INTERN"
            />
            <TeamPerson
              imageSrc="/team/16 PAUL.png"
              name="PAUL ABHRONIL"
              role="HARDWARE ENGINEERING INTERN"
            />
            <TeamPerson
              imageSrc="/team/17 FEDERICO.png"
              name="FEDERICO FERRARI"
              role="FINANCE INTERN"
            />
            <TeamPerson
              imageSrc="/team/18 LORENZO.png"
              name="LORENZO PIRES DE LUCCA"
              role="OPERATIONS INTERN"
            />
            <TeamPerson
              imageSrc="/team/19 VALENTINA.png"
              name="VALENTINA BARRI"
              role="MARKETING SPECIALIST"
            />




            
          </div>
        </div>

        <div id="timeline" className="px-10 pt-40">
          <h1 className="text-4xl md:text-6xl  font-bold text-white text-center py-16 uppercase">
            How We've (R)evolved
          </h1>

          <div  data-aos="zoom-in-out"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600">
          <CircleSlider></CircleSlider>

          </div>
        </div>

        {/* TRUSTED BY */}
        <div className="flex flex-col  relative space-y-10 pt-20">


          <div className="flex flex-col  relative space-y-10 pt-20">
            <div className="flex items-center w-full ">
              <div className="grid md:grid-cols-4 text-white w-full mx-5">
                <div className="md:col-span-4 flex justify-center pb-16">
                  <h1 className="text-4xl md:text-6xl font-bold uppercase ">Supporters</h1>
                </div>
                <div className="col-span-1 lg:my-[8rem] my-[2rem] flex flex-col items-center "  data-aos="fade-zoom-in" data-aos-offset="200" data-aos-delay="300" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                  <div className="flex items-center justify-center w-full h-full">
                    <img
                      className="object-cover h-10 sm:h-12 md:h-16 w-auto"
                      src="/about_icons/new/Logo - ESA BIC Noordwijk - White.svg"
                      alt="u-space"
                    />
                  </div>
                </div>
                
                
                <div className="col-span-1 lg:my-[8rem] my-[2rem] flex flex-col items-center "  data-aos="fade-zoom-in" data-aos-offset="200" data-aos-delay="400" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                  <div className="flex items-center justify-center w-full h-full">
                    <img
                      className="object-cover h-10 sm:h-12 md:h-16 w-auto"
                      src="/about_icons/new/plug-and-play-logo-blue.451860baa333 copia.svg"
                      alt="plugandplay"
                    />
                  </div>
                </div>
                
                <div className="col-span-1 lg:my-[8rem] my-[2rem] flex flex-col items-center"  data-aos="fade-zoom-in" data-aos-offset="200" data-aos-delay="500" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                  <div className="flex items-center justify-center w-full h-full">
                    <img
                      className="object-cover h-10 sm:h-12 md:h-16 w-auto"
                      src="/about_icons/PoloICT_Bando_Metastars-1 1.svg"
                      alt="meta"
                    />
                  </div>
                </div>
                <div className="col-span-1 lg:my-[8rem] my-[2rem] flex flex-col items-center"  data-aos="fade-zoom-in" data-aos-offset="200" data-aos-delay="600" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                  <div className="flex items-center justify-center w-full h-full">
                    <img
                      className="object-cover h-10 sm:h-12 md:h-16 w-auto"
                      src="/about_icons/image-6 1.svg"
                      alt="provincie Zuid Holland"
                    />
                  </div>
                  
                </div>
                <div className="col-span-1 lg:my-[8rem] my-[2rem] flex flex-col items-center"  data-aos="fade-zoom-in" data-aos-offset="200" data-aos-delay="600" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                  <div className="flex items-center justify-center w-full h-full">
                    <img
                      className="object-cover h-10 sm:h-12 md:h-16 w-auto"
                      src="/about_icons/new/TAKEOFF_Logo_NoTag_RGB_color_d0.svg"
                      alt="TakeOff"
                    />
                  </div>
                  
                </div>
                <div className="col-span-1 lg:my-[8rem] my-[2rem] flex flex-col items-center"  data-aos="fade-zoom-in" data-aos-offset="200" data-aos-delay="600" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                  <div className="flex items-center justify-center w-full h-full">
                    <img
                      className="object-cover h-10 sm:h-12 md:h-16 w-auto"
                      src="/about_icons/new/primo_ventures_.svg"
                      alt="primo"
                    />
                  </div>
                  
                </div><div className="col-span-1 lg:my-[8rem] my-[2rem] flex flex-col items-center"  data-aos="fade-zoom-in" data-aos-offset="200" data-aos-delay="600" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                  <div className="flex items-center justify-center w-full h-full">
                    <img
                      className="object-cover h-10 sm:h-12 md:h-16 w-auto"
                      src="/about_icons/new/Gra-duate.png"
                      alt="graduate"
                    />
                  </div>

                </div>

                <div className="col-span-1 lg:my-[8rem] my-[2rem] flex flex-col items-center"  data-aos="fade-zoom-in" data-aos-offset="200" data-aos-delay="600" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                  <div className="flex items-center justify-center w-full h-full">
                    <img
                      className="object-cover h-10 sm:h-12 md:h-16 w-auto"
                      src="/about_icons/new/SYSGO_logo_alliances_ansys.svg"
                      alt="SYSGO"
                    />
                  </div>

                </div>

              </div>
            </div>

            <div className="flex items-center justify-center pt-20">
            <Link to="https://careers.revolvspace.com/" replace>
              <button
                className=" rounded-full font-bold w-auto h-10 text-center text-white  px-4 transform transition-transform duration-200 hover:scale-110"
                style={{
                  background:
                    "linear-gradient(90deg, #00858F 0%, #E6007E 100%)",
                }}
              >
                JOIN OUR TEAM
              </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
