import "../global.css";
import React, { useEffect } from "react";
import {Link} from "react-router-dom";
import PipedriveForm from "../components/PipedriveForm";

export default function Contact() {
    useEffect(() => {
        if (window.location.hash) {
          let newPath = window.location.pathname + window.location.search;
          if (newPath.endsWith('/')) {
            console.log("termina con /", newPath)
            newPath = newPath.slice(0, -1);
            console.log("newPath", newPath)
          }
          window.history.pushState("", document.title, newPath);
        }
      }, []);

    const contacts = [
        {
            title: "SALES",
            description:
                "SALES@REVOLVSPACE.COM",
            link: "mailto:sales@revolvspace.com",
        },
        {
            title: "INVESTORS",
            description:
                "INVESTORS@REVOLVSPACE.COM",
            link: "mailto:investors@revolvspace.com",
        },
         {
            title: "SUPPLIERS",
            description:
                "SUPPLIERS@REVOLVSPACE.COM",
            link: "mailto:suppliers@revolvspace.com",
        },
        {
            title: "GENERAL CONTACT",
            description:
                "CONTACT@REVOLVSPACE.COM",
            link: "mailto:contact@revolvspace.com",
        }
    ];
    return (
        <div>
          
            <div className="relative flex flex-col h-full mt-20 ">
                {/* BECOME A REVOLVER Section */}
                <div className="h-full flex flex-col   z-40 ">
                    <h1 className="opacity-0 animate-fadeIn delay-0 md:w-4/6 pb-5 mx-12 mt-24  2xl:text-7xl xl:text-7xl lg:text-7xl text-4xl leading-120 tracking-tighter text-left uppercase font-bold text-white dark:text-white  ">
                        GET IN TOUCH
                    </h1>
                    <div className="opacity-0 animate-fadeIn delay-1 ml-12 mr-10 uppercase 2xl:text-2xl xl:text-2xl lg:text-2xl text-md  text-white leading-34 tracking-tight text-left max-w-[800px]">
                        CURIOUS? REACH OUT, WE ARE ALWAYS HERE TO CHAT!
                    </div>

                    {/*spazio per bottoni*/}
                    <div
                        className="flex flex-col   h-10 sm:flex-row justify-center items-center gap-2 md:gap-24 py-12 font-bold ">
                    </div>


                </div>
            </div>

            {/* CONTACTS Section */}
            <div className="h-full flex flex-col bg-black  bg-opacity-50 z-50 mb-40 ">
                <h1
                    id='positions'
                    className="opacity-0 animate-fadeIn delay-2 my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-medium text-white ">
                    Or just say hello!{" "}
                </h1>
                <h1 className="opacity-0 animate-fadeIn delay-3 mb-24 -mt-8 text-2xl leading-120 tracking-tighter text-center uppercase font-light text-white ">
                    CLICK ON THE email TO contact us!{" "}
                </h1>
                <div>
                <div
                    data-aos="fade-zoom-in"
                    data-aos-offset="300px"
                    data-aos-delay="0"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="600"
                    className="flex flex-col gap-4 mt-10 sm:mt-0 z-0 items-center ">                        {" "}
                        {/* Add top margin on mobile */}
                        {contacts.map((contact, index) => (
                            <a
                                key={index}
                                href={contact.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className=""
                            >
                                <div
                                    className="with-border relative group mx-10 p-4 transition-all duration-200 overflow-hidden">
                                    <div
                                        className="absolute  inset-0 transform -scale-x-3 -scale-y-3 group-hover:scale-100 transition-transform duration-200"></div>
                                    <div
                                        className="  relative z-40 flex  sm:flex-row justify-between items-center">
                                        {" "}
                                        {/* Make it a column on mobile */}
                                        <div className=" w-80">
                                            {" "}
                                            {/* Adjust as needed */}
                                            <h2 className="text-lg font-bold text-white uppercase">
                                                {contact.title}
                                            </h2>
                                            <p className="text-sm text-white uppercase">
                                                {contact.description}
                                            </p>
                                        </div>
                                        <div
                                            className="opacity-0 group-hover:opacity-100 transition-opacity duration-200
                                                 absolute right-0 bottom-0"
                                        >
                                            <img
                                                className="h-6 w-6 fill-current text-blue-500"
                                                src="/commons/arrow_forward.svg"
                                                alt="arrow"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            </div>

            <div className="max-w-md mx-auto mt-10 p-6 "  data-aos="fade-zoom-in"
                 data-aos-offset="300px"
                 data-aos-delay="0"
                 data-aos-easing="ease-in-sine"
                 data-aos-duration="600">
                <PipedriveForm formUrl="https://webforms.pipedrive.com/f/6OCFAtifgjHY9kWFyPLwLuwFJJimchaNkzG5B6QJVNlFvrq3vvNLaft2ZPYQWHUJFx"/>
            </div>

            {/*MAPS + IMAGE SECTION*/}

            <div
                className="h-full w-full py-40 bg-[url('/public/REVOLVE_SPACE_CONTACT.svg')]  bg-center z-40  px-10 flex flex-col items-center justify-center  ">

                <div>
                    <h1
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="0"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        id='positions'
                        className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-medium text-white ">
                        From Earth to the Moon{" "}
                    </h1>
                    <h1
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="400"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        className=" mb-24 -mt-8 text-2xl leading-120 tracking-tighter text-center uppercase font-light text-white">
                        BUT YOU COULD ALWAYS FIND US HERE: {" "}
                    </h1>
                </div>
                <div className="grid  grid-cols-2 xl:gap-x-40 md:gap-x-24 text-white mb-20">

                    <Link to="https://www.google.com/maps/place//data=!4m2!3m1!1s0x47886d9e7a19b85d:0x18041d363fa9c73c?sa=X&ved=1t:8290&ictx=111" target="_blank" rel="noopener noreferrer" >
                    <button
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="0"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        className="col-span-1  pb-5  transform transition-transform duration-200 hover:scale-110">

                        <div className="pb-10 h-52">
                            <img src="/contacts/star1.svg" alt=""/>
                        </div>
                        <div className="text-left">
                            <h2 className="font-bold text-2xl uppercase">revolv space srl</h2>
                            <p className="uppercase">Corso Re Umberto 7 <br/> 10121 Torino <br/> Italy</p>
                        </div>
                    </button>
                    </Link>

                    <Link to="https://www.google.com/maps/place/Kapteynstraat+1,+2201+CZ+Noordwijk,+Paesi+Bassi/@52.2137096,4.4302315,17z/data=!3m1!4b1!4m6!3m5!1s0x47c5c0a2b1d0b5a7:0xaa305e96d464dd2!8m2!3d52.2137063!4d4.4328064!16s%2Fg%2F11cs6zdy2j?entry=ttu" target="_blank" rel="noopener noreferrer" >
                    <button
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="400"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        className="   col-span-1 pb-5 transform transition-transform duration-200 hover:scale-110">
                        <div className="pb-10 h-52">
                            <img src="/contacts/stars3.svg" alt=""/>
                        </div>
                        <div className="text-left">
                            <h2 className="font-bold text-2xl uppercase">revolv space bv</h2>
                            <p className="uppercase">Coenderstraat 5<br/>2613 SM Delft<br/> The Netherlands</p>

                        </div>
                    </button>
                    </Link>

                </div>
            </div>
        </div>
    );
}
