import React from "react";
interface AdditionalText {
    title: string;
    description: string;
}
interface TechSpecsProps {
    title: string;
    description: AdditionalText[];
}

const TechSpecs: React.FC<TechSpecsProps> = ({ title, description }) => {
    return (
        <div className=" with-border relative group   p-4 transition-all duration-200 overflow-hidden">
            <div className="absolute inset-0 transform -scale-x-3 -scale-y-3 group-hover:scale-100 transition-transform duration-200"></div>
            <div className="relative z-10 flex flex-col sm:flex-row justify-between items-center">
                <div className="max-w-[calc(100%-1.5rem)] ">
                    <h2 className="text-lg font-bold text-white uppercase">{title}</h2>
                    <p className="text-sm text-white uppercase">
                        {description.map((text, index) => (
                            <div key={index} className="my-4 text-left">
                                <h2 className="font-bold uppercase">{text.title}</h2>
                                <p className="uppercase" dangerouslySetInnerHTML={{ __html: text.description.replace(/\n/g, '<br />') }}></p>
                            </div>
                        ))}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TechSpecs;
