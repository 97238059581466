import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logPageView } from '../types/ga'; // Adjust the import path accordingly

interface GAListenerProps {
    children: React.ReactNode;
}

const GAListener: React.FC<GAListenerProps> = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        logPageView(location.pathname, document.title);
    }, [location]);
    //console.log('Analytics ID:', process.env.REACT_APP_ANALYTICS);  //Decommenta il codice qui assinistra per verificare da console il caricamento del giusto ID del GA
    return <>{children}</>;
};

export default GAListener;
