// src/pages/Configuratore_New.tsx
import React, { useState } from "react";
import { Canvas } from "@react-three/fiber";
import { Box, Environment, OrbitControls } from "@react-three/drei";
import ModelsScene from "../common/configuratore/ModelsScene";
import HierarchyControls from "../common/configuratore/HierarchyControls";
import ConfiguratorePanel from "../common/configuratore/ConfiguratorePanel";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import PageHeader from "../components/PageHeader";
import "../index.css";
import { isMobile } from 'react-device-detect';


interface ConfiguratoreNewProps {
  modelPath: string | null;
  hierarchy: NodeHierarchy | null;
  visibility: { [key: string]: boolean };
  setVisibility: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  onHierarchyChange: (newHierarchy: NodeHierarchy) => void;
  loading: boolean;
}

interface NodeHierarchy {
  name: string;
  children: { [key: string]: NodeHierarchy };
}

const Configuratore_New: React.FC<ConfiguratoreNewProps> = ({
  modelPath,
  hierarchy,
  onHierarchyChange,
  visibility,
  setVisibility,
  loading,
}) => {
  const hdrPath =
    process.env.PUBLIC_URL + "/configuratore/hdr/HDR_artificial_planet.hdr";

  //   const [visibility, setVisibility] = useState<{ [key: string]: boolean }>({});
  //   const [hierarchy, setHierarchy] = useState<NodeHierarchy | null>(null);
  //   const [modelPath, setModelPath] = useState<string>(
  //     "/configuratore/models/satellitetest/U3_SIDE_TOP.gltf"
  //   ); // default model path

  const buildHierarchy = (node: any): NodeHierarchy => {
    let children: { [key: string]: NodeHierarchy } = {};
    if (node.children) {
      node.children.forEach((child: any) => {
        children[child.name] = buildHierarchy(child);
      });
    }
    return {
      name: node.name,
      children: children,
    };
  };

  const handleModelLoaded = (gltf: GLTF) => {
    onHierarchyChange(buildHierarchy(gltf.scene));
  };

  //   const toggleVisibility = (name: string) => {
  //     setVisibility((prev) => ({ ...prev, [name]: !prev[name] }));
  //   };

  return (
    //  loading ? (
    //   <div>Loading...</div>
    // ) : (
    <div>

      <div className="absolute uppercase flex justify-center w-full mt-4 text-white text-xl font-bold z-10">
         3D view: click and drag
      </div>


      <div className={`w-full h-screen pt-50 `}>
        <Canvas
          camera={{ position: [0, 0, 10], fov: 75 }}
          shadows
          dpr={[1, 1.5]}
          gl={{ antialias: false }}
        >
          <ambientLight intensity={20} />
          {/*<pointLight position={[10, 10, 10]} intensity={100} />
              <Box position={[10, 10, 10]}/>
              <pointLight position={[5, 0, 10]} intensity={100} />
              <Box position={[5, 0, 10]}/>
              <pointLight position={[5, 5, 10]} intensity={100} />
              <Box position={[5, 5, 10]}/>
*/}
          <directionalLight intensity={30} />
          <pointLight position={[-10, 5, 10]} intensity={300} />
          <pointLight position={[0, 5, 10]} intensity={300} />
          <pointLight position={[5, 5, -5]} intensity={300} />
          <pointLight position={[-20, 5, -10]} intensity={300} />
          <pointLight position={[-10, -5, -10]} intensity={300} />
          <pointLight position={[-10, -5, 10]} intensity={300} />
          <pointLight position={[10, -5, 10]} intensity={300} />
          <pointLight position={[10, -5, -10]} intensity={300} />

          {/*<pointLight position={[-7, 0, 10]} intensity={300} />
              <Box position={[-7, 0, 10]}/>

              <pointLight position={[-5, 5, 10]} intensity={300} />
              <Box position={[-5,5, 10]}/>

              <pointLight position={[5, 0, -10]} intensity={100} />
              <Box position={[5, 0, -10]}/>
              <pointLight position={[5, 5, -10]} intensity={100} />
              <Box position={[5, 5, -10]}/>
              <pointLight position={[10, 10, -10]} intensity={100} />
              <Box position={[10, 10, -10]}/>
              <pointLight position={[10, -10, 10]} intensity={100} />
              <Box position={[10, -10, 10]}/>
              <pointLight position={[-10, -10, 10]} intensity={200} />
              <Box position={[-10, -10, 10]}/>
              <pointLight position={[-10, -10, -10]} intensity={200} />
              <Box position={[-10, -10, -10]}/>
              <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} intensity={100} castShadow />
              <Box position={[10, 10, 10]}/>*/}

          <ModelsScene
            setVisibility={setVisibility}
            handleModelLoaded={handleModelLoaded}
            visibility={visibility}
            modelPath={modelPath}
          />

          <OrbitControls enablePan={false} />
          {!isMobile && (
        <Environment files={hdrPath} background backgroundIntensity={10} />
      )}        </Canvas>
      </div>
      {/* <HierarchyControls
                hierarchy={hierarchy}
                toggleVisibility={toggleVisibility}
                visibility={visibility}
            /> */}
      {/* <ConfiguratorePanel setModelPath={setModelPath} /> */}
    </div>
  );
  // </div>
};

export default Configuratore_New;
