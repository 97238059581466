import Slider from 'react-slick';
import "./ImageSlider.css";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const settings = {
    dots: false,
    arrows: true, 
    infinite: true,
    speed: 500,
    slidesToShow:2,
    slidesToScroll: 1,
    swipeToSlide: true,
    centerMode: true,
};

const SliderHolly = () => (
    <Slider {...settings} className="flex">
        <img src="/products/Holly/Product gallery/HOLLY (shipment) 1.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/products/Holly/Product gallery/HOLLY (shipment) 2.jpg" alt="" className="h-[500px] object-contain " />
        <img src="/products/Holly/Product gallery/HOLLY (shipment) 3.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/products/Holly/Product gallery/HOLLY (shipment) 4.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/products/Holly/Product gallery/HOLLY.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/products/Holly/Product gallery/HOLLY (lab).jpg" alt="" className=" h-[500px] object-contain " />
    </Slider>
);





export default SliderHolly;