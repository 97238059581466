import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function RedirectPage() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const externalUrl = urlParams.get("url");

    if (externalUrl) {
      // Usa window.location.href per reindirizzare a un URL esterno
      window.location.replace(externalUrl);
    } else {
      // Usa navigate() per restare nell'app React senza perdere cronologia
      navigate("/home", { replace: true }); // Se manca l'URL, torna alla home
    }
  }, [navigate, location]);

  return <p>Redirecting...</p>;
}