import { Disclosure } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

interface NavigationItem {
  name: string;
  href: string;
  current: boolean;
  children?: NavigationItem[];
}

const navigation: NavigationItem[] = [
  { name: "/home", href: "/home", current: true },
  {
    name: "/products",
    href: "/products",
    current: false,
    children: [
      { name: "/products/sara", href: "/products/sara", current: false },
      /*{ name: "/PAVEL", href: "/PAVEL", current: false },*/
      { name: "/products/Holly", href: "/products/holly", current: false }
    ],
  },
  { name: "/about", href: "/about", current: false },

  { name: "/careers", href: "https://careers.revolvspace.com", current: false },
  { name: "/news", href: "/news", current: true },
  
  { name: "/contact", href: "/contact", current: true },

  { name: "/Build Now", href: "/configuratore", current: false },
];

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
  const [currentItem, setCurrentItem] = useState<string>("HOME");
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false); // new state for dropdown

  const navigate = useNavigate();

  const handleMenuItemClick = (name: string) => {
    console.log(name);
    
    
    if (name === "/products") {
      setDropdownOpen(!dropdownOpen);
    } else {
      setDropdownOpen(false);
      setCurrentItem(name);
      setIsMenuOpen(false);
      let nav= navigation.find(item => item.name === name)?.href;
      {/*if(nav!==null && nav!==undefined)
      navigate(nav);*/}

      {/*codice aggiunto per abilitare il collegamento di url esterni(careers)*/}

      if (nav) {
        if (nav.startsWith("http")) {
          navigate(`/redirect?url=${encodeURIComponent(nav)}`); // Naviga direttamente senza aprire una nuova scheda
        } else {
          navigate(nav);
        }
      }


    }
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {

    if (location.pathname.endsWith('/')) {
      location.pathname = location.pathname.slice(0, -1);
    }
    location.pathname === "/configuratore"
      ? setCurrentItem("/Build Now")
      : navigation.find(item => item.name === location.pathname) || navigation.find(item => item.children?.find(x => x.name === location.pathname))? 
      setCurrentItem(location.pathname): console.log("no" + location.pathname);

  }, [location.pathname]);
  return (
    <div className="bg-black fixed top-0 left-0 right-0 z-50">
      <div className="mx-auto max-w-full py-6  px-4 sm:px-4 lg:px-4 ">
        <div className="relative flex items-center justify-between gap-6">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Link to="/home">
                <img
                  className="h-8 w-auto max-w-xs"
                  src="/logo_def.svg"
                  alt="Revolv Space Logo"
                />
              </Link>
            </div>
          </div>
          <div className=" gap-6 items-center hidden md:flex md:justify-between">   {/*menu grande*/}
            {navigation.map((item: NavigationItem) => (
              <div className="relative group" key={item.name}>
                {item.children ? (
                  <button
                    onClick={() => handleMenuItemClick(item.name)}
                    className={classNames(
                      item.name === "/Build Now"
                        ? "bg-gradient-to-r from-gradient-start to-gradient-end text-white px-1 text-md text-center p-2"
                        : currentItem.startsWith(item.name)
                        ? "font-extrabold text-black uppercase "
                        : " hover:text-pink-900 uppercase ",
                      "text-white sm:text-md leading-32 "
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name.replace("/", "")}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={`h-5 w-5 inline-block transform transition-transform duration-200 ${
                        dropdownOpen ? "rotate-180" : ""
                      }`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                ) : (
                  <button
                    onClick={() => handleMenuItemClick(item.name)}
                    className={classNames(
                      item.name === "/Build Now"
                      ? "bg-gradient-to-r from-gradient-start rounded-full to-gradient-end font-bold text-white px-2 py-2 text-md uppercase transform transition-transform duration-200 hover:scale-110"                        
                      : currentItem === item.name|| (item.name === "/products" && currentItem.startsWith("/products"))
                        ? "font-extrabold text-white uppercase "
                        : " hover:text-pink-900 uppercase  ",
                      "text-white sm:text-md leading-32 "
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name.replace("/", "")}{" "}
                  </button>
                )}
                {item.children && dropdownOpen && (
                  <div className="absolute left-0 w-48 py-2 mt-1 bg-black text-white block">
                    {item.children.map((child: NavigationItem) => (
                      <Link
                        to={child.href}
                        key={child.name}
                        onClick={() => handleMenuItemClick(child.name)}
                        className={classNames(
                          currentItem === child.name
                            ? "block px-4 py-2 font-bold uppercase"
                            : "block px-4 py-2 uppercase "
                        )}
                      >
                        {child.name.replace("/products/", "")}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="block text-white hover:text-gray-300 focus:text-gray-300 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                {isMenuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>
        <div
          className={`${isMenuOpen ? "block md:hidden" : "hidden md:hidden"}`}
        >
          <div className="fixed inset-y-0 right-0 max-w-xs w-full bg-black shadow-lg ">
            <div className="p-4">
              <nav className="flex flex-col gap-2 mt-4">
                <div className="text-white inset-y-0 right-0 fixed pt-5 pb-6 pr-4">
                  <button
                    onClick={() => setIsMenuOpen(false)}
                    className="self-end"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="pt-12 flex flex-col">
                {navigation.map((item) =>
                  item.children ? (
                    <Disclosure as="div" key={item.name} className="">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex uppercase pb-4 items-center justify-between w-full text-white hover:text-pink-900">
                            {item.name.replace("/", "")}{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className={`h-5 w-5 inline-block transform transition-transform duration-200 ${
                                open ? "rotate-180" : ""
                              }`}
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 9l-7 7-7-7"
                              />
                            </svg>
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-5 pb-2 space-y-1 flex flex-col">
                            {item.children?.map((child) => (
                              <Link
                                to={child.href}
                                key={child.name}
                                onClick={() => handleMenuItemClick(child.name)}
                                className={classNames(
                                  currentItem === child.name
                                    ? "bg-lightBlue text-white uppercase font-extrabold "
                                    : "text-white hover:bg-lightBlue  hover:text-pink-900 uppercase"
                                )}
                              >
                                {child.name.replace("/products/", "")}{" "}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : (
                    <Link
                      to={item.href}
                      key={item.name}
                      onClick={() => handleMenuItemClick(item.name)}
                      className={classNames(
                        currentItem === item.name
                          ? "bg-lightBlue text-white uppercase font-extrabold pb-4"
                          : "text-white hover:bg-lightBlue hover:text-pink-900 uppercase pb-4"
                      )}
                    >
                      {item.name.replace("/", "")}{" "}
                    </Link>
                  )
                )}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
