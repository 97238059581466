import React, { useState } from "react";

interface AdditionalText {
    description: string;
}
interface CollapsibleProps {
    title: string;
    additionalText: AdditionalText[];
}
const Collapsible: React.FC<CollapsibleProps> = ({ title, additionalText }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div
            className={`w-full text-center text-white px-4 py-4 cursor-pointer rounded-3xl ${
                isOpen ? "h-auto flex-col items-start" : "h-auto w-full"
            }`}
            style={{ background: "linear-gradient(90deg, #00858F 0%, #E6007E 100%)" }}
            onClick={() => setIsOpen(!isOpen)}
        >
            <div className="relative flex justify-center items-center ">
                <img
                    src="/commons/arrow-down.svg"
                    className={`absolute top-0 left-0 h-8 w-8 transform transition-transform duration-200 ${
                        isOpen ? "rotate-180" : ""
                    }`}
                />
                <h1 className="uppercase text-center font-medium text-2xl ">{title}</h1>
            </div>
            {isOpen &&
                <ul className="list-disc pl-5">
                    {additionalText.map((text, index) => (
                        <li key={index} className="my-4 text-left">
                            <p className="uppercase">{text.description}</p>
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
};
export default Collapsible;
